import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import media from "styled-media-query"
import React, { useState } from "react"

const Container = styled.div`
  transition: all 0.5s;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  background: ${props => (props.show ? "rgba(0, 0, 0, 0.5)" : "rgba(0,0,0,0)")};
`

const Card = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 98%;
  height: 60%;
  background: rgba(245, 245, 245, 0.95);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  transition: all 0.5s ease-in-out;
  transform: ${props => (props.show ? "translateY(0)" : "translateY(-100vh)")};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  padding-left: 12px;
  position: relative;
`

const Title = styled.h2`
  font-family: Lack, sans-serif;
  font-size: 6rem;
  margin: 0;
`
const Paragraph = styled.p`
  font-size: 1.25rem;
  line-height: 150%;
  padding-left: 4px;
`

const Modal = () => {
  const [show, setShow] = useState(true)
  const keyHideModal = e => {
    if (e.key === "Escape") {
      setShow(false)
    }
  }
  const mouseHideModal = e => {
    if (show) {
      setShow(false)
    }
  }

  return (
    <Container
      show={show}
      tabIndex="0"
      onClick={mouseHideModal}
      onKeyUp={keyHideModal}
    >
      <Card show={show}>
        <Content onClick={e => e.stopPropagation()}>
          <Title>We're closed!</Title>
          <Paragraph>
            Crrumb is no longer operating.
            <br /> If you have any questions, feel free to contact me (rondie at
            crrumb.com) <br />
            <br />
            See ya :)
          </Paragraph>
        </Content>
      </Card>
    </Container>
  )
}
// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Modal
