import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import { Header } from "./"
import 'typeface-ibm-plex-mono'
import {
  LackRegularEOT,
  LackRegularWOFF,
  LackRegularWOFF2
} from "../fonts"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Lack;
    src: url(${LackRegularEOT}); /* IE9 Compat Modes */
    src: url(${LackRegularEOT}) format('embedded-opentype'), /* IE6-IE8 */
         url(${LackRegularWOFF2}) format('woff2'), /* Super Modern Browsers */
         url(${LackRegularWOFF}) format('woff'); /* Pretty Modern Browsers */
  }

  html, body {
    background: #f1e2cd;
    color: #2C291D;
    font-family: 'IBM Plex Mono', serif;
  }

  h1, h2, h3, h4, h5, h6 {
     font-family: Lack, webdings;
  }
`


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <GlobalStyle />
        <div>
          <main>{children}</main>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
