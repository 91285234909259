import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const LogoStyled = styled.div`
  svg {
    width: 100px;
    path {
    fill: #B5473C;
    }
  ${media.lessThan("medium")`
    width: 125px;
    `}
  }
`

const Logo = () => (
  <LogoStyled>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 171.7 45.9"
    >
      <path d="M26,31.9c-1.5,1-3.3,1.4-5.4,1.4c-1.5,0-2.8-0.3-4-0.8c-1.2-0.6-2.2-1.3-3-2.3c-0.8-0.9-1.5-2.1-1.9-3.3      c-0.5-1.3-0.7-2.6-0.7-4c0-1.6,0.2-3,0.7-4.3c0.5-1.3,1.1-2.4,2-3.3c0.8-0.9,1.8-1.7,3-2.2c1.2-0.5,2.5-0.8,4-0.8      c2,0,3.7,0.4,5.1,1.3c1.4,0.8,2.5,2.2,3.2,4.1h6.8c-0.4-2-1.1-3.8-2.1-5.2c-1-1.5-2.2-2.7-3.6-3.6c-1.4-0.9-2.9-1.6-4.5-2.1      c-1.6-0.5-3.3-0.7-4.9-0.7c-2.1,0-4.2,0.3-6.2,1c-2,0.7-3.8,1.8-5.3,3.2c-1.6,1.4-2.8,3.2-3.7,5.3C4.4,17.9,4,20.3,4,23      c0,2.7,0.5,5.1,1.4,7.2c0.9,2.1,2.2,3.8,3.7,5.2c1.6,1.4,3.3,2.5,5.3,3.2c2,0.7,4.1,1.1,6.2,1.1c1.7,0,3.4-0.2,5.1-0.7      c1.7-0.5,3.2-1.2,4.5-2.2c1.4-1,2.5-2.2,3.5-3.8c1-1.6,1.7-3.4,2-5.5h-6.7C28.5,29.5,27.5,31,26,31.9z" />
      <path d="M97.5,34.6c-0.2-0.4-0.3-1.1-0.3-2.2V16.8h-6.5v12.7c0,0.7-0.1,1.4-0.4,2.1c-0.2,0.6-0.6,1.2-1,1.6c-0.4,0.5-0.9,0.8-1.5,1      c-0.6,0.2-1.2,0.4-1.8,0.4c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.8-1.1-2-1-3.6v-13h-6.6v13c0,1.4,0.1,2.7,0.4,3.9c0.3,1.2,0.7,2.3,1.4,3.1      c0.6,0.9,1.5,1.6,2.6,2.1c1.1,0.5,2.4,0.8,4,0.8c0.6,0,1.3-0.1,2-0.2c0.7-0.2,1.4-0.4,2.1-0.7c0.7-0.3,1.3-0.7,1.9-1.2      c0.6-0.5,1-1,1.3-1.7c0,1.1,0.3,1.9,0.8,2.5c0.5,0.5,1.2,0.9,2.1,1.1c0.8,0.2,1.7,0.2,2.6,0.1c0.9-0.1,1.8-0.2,2.6-0.4l0-4.1      C98.3,35.2,97.7,35.1,97.5,34.6z" />
      <path d="M139.5,34.5c-0.3-0.5-0.4-1.3-0.4-2.3v-6c0-1.4-0.1-2.7-0.3-3.9c-0.2-1.2-0.6-2.2-1.1-3.1c-0.6-0.9-1.3-1.6-2.4-2.1      c-1-0.5-2.3-0.8-3.9-0.8c-1.6,0-3.1,0.3-4.5,1c-1.4,0.7-2.5,1.7-3.2,3.2c-0.5-1.4-1.3-2.4-2.4-3.1c-1.1-0.7-2.6-1-4.5-1      c-1.5,0-2.9,0.3-4.2,1c-1.3,0.6-2.2,1.7-2.8,3.1c0-1.1-0.3-2-0.9-2.6c-0.6-0.6-1.4-1-2.2-1.2c-0.9-0.2-1.8-0.3-2.9-0.2      c-1,0.1-1.9,0.2-2.8,0.5v3.8c0.6,0,1,0,1.4,0.1c0.3,0.1,0.6,0.3,0.7,0.5c0.2,0.3,0.3,0.6,0.3,1c0,0.4,0.1,1,0.1,1.6v15.4h6.5V26.4      c0-1.6,0.4-2.8,1.3-3.7c0.9-0.9,1.9-1.3,3.1-1.3c1.3,0,2.3,0.4,2.9,1.3c0.7,0.8,1,2,1,3.5v13h6.3v-13c0-1.5,0.4-2.7,1.2-3.5      c0.8-0.9,1.8-1.3,2.9-1.3c1.2,0,2.1,0.4,2.8,1.3c0.7,0.8,0.9,2,0.9,3.5v7.5c0,1.4,0.2,2.6,0.7,3.4c0.5,0.8,1.2,1.4,2,1.8      c0.8,0.4,1.8,0.5,2.9,0.5c1.1,0,2.3-0.2,3.5-0.5v-3.8C140.5,35.2,139.8,35,139.5,34.5z" />
      <path d="M166.9,22.8c-0.6-1.5-1.3-2.7-2.2-3.6c-0.9-0.9-2-1.7-3.1-2.1c-1.2-0.5-2.4-0.7-3.6-0.7c-1.6,0-2.9,0.3-4,0.8      c-1,0.5-2,1.5-2.7,2.8V5.5h-6v32.2c1.5,0.6,3,1.1,4.6,1.5c1.6,0.4,3.2,0.6,4.9,0.6c1.7,0,3.3-0.2,4.9-0.6c1.6-0.4,2.9-1.1,4.1-2      c1.2-0.9,2.1-2.1,2.8-3.6c0.7-1.5,1.1-3.3,1.1-5.5C167.7,26,167.5,24.3,166.9,22.8z M159.5,32.9c-1.1,1-2.4,1.5-4.1,1.5      c-0.6,0-1.2-0.1-1.9-0.2c-0.6-0.1-1.2-0.3-1.9-0.5v-5.6c0-0.8,0.1-1.5,0.2-2.3c0.2-0.8,0.4-1.4,0.8-2c0.4-0.6,0.8-1.1,1.4-1.4      c0.6-0.4,1.3-0.5,2.1-0.5c0.8,0,1.6,0.2,2.2,0.5c0.6,0.3,1.1,0.8,1.6,1.3c0.4,0.6,0.7,1.2,0.9,2c0.2,0.8,0.3,1.6,0.2,2.4      C161.1,30.3,160.5,31.9,159.5,32.9z" />
      <path d="M70.5,16.6c-0.8-0.2-1.7-0.4-2.6-0.3c-0.7,0-1.4,0.1-2.1,0.2c-0.7,0.2-1.3,0.4-1.9,0.7c-0.6,0.3-1.1,0.7-1.6,1.2      c-0.4,0.4-0.8,1-1,1.6c-0.5-1.2-1.2-2.1-2.2-2.7c-0.7-0.5-1.7-0.8-2.9-1h0c-0.5-0.1-1-0.1-1.6-0.1c-1.5,0-2.9,0.3-4.2,1      c-1.3,0.6-2.2,1.7-2.8,3.1c0-1.2-0.3-2-0.9-2.6c-0.6-0.6-1.4-1-2.2-1.2c-0.9-0.2-1.8-0.3-2.9-0.2c-1,0.1-1.9,0.2-2.7,0.5v3.8      c0.6,0,1,0,1.4,0.1c0.3,0.1,0.6,0.3,0.7,0.5c0.2,0.2,0.3,0.6,0.3,1c0,0.4,0.1,1,0.1,1.6v15.4H48V26.4c0-1.6,0.4-2.8,1.3-3.7      c0.9-0.9,1.9-1.3,3.1-1.3c1,0,1.8,0.2,2.4,0.7l0,0c0.2,0.1,0.4,0.3,0.5,0.5c0.7,0.8,1,2,1,3.5v13h5.5V27.3c0-0.9,0.1-1.6,0.4-2.3      c0.3-0.7,0.7-1.2,1.1-1.7c0.5-0.4,1-0.8,1.7-1c0.6-0.2,1.3-0.3,2-0.3c1.5-0.1,2.9,0.3,4.2,1.2l1.4-5.5      C72.1,17.3,71.3,16.9,70.5,16.6z" />
    </svg>
  </LogoStyled>
)

export default Logo
