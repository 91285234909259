import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import media from "styled-media-query"
import React from "react"
import { Logo } from "./"

const NavWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Nav = styled.div`
  display: flex;
  position: relative;
  ${media.lessThan("medium")`
  display: none;
    `}
  p {
    font-size: 1rem;
    font-family: "Lack";
    margin: 0;
  }
  p:nth-of-type(-n + 2) {
    margin-right: 12px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: -2%;
    height: 6px;
    width: 104%;
    transition: all 0.2s;
  }
  &::after {
    opacity: 0;
    content: "under construction";
    font-family: "Lack";
    font-size: 0.75rem;
    position: absolute;
    top: 44px;
    right: 0;
    transition: all 0.2s;
    transition-delay: 0.2s;
  }
  &:hover {
    cursor: crosshair;
    &::before {
      background: #2c291d;
    }
    &::after {
      opacity: 1;
      top: 28px;
    }
  }
`

const Header = ({ siteTitle }) => (
  <header>
    <NavWrap>
      <Link to="/">
        <Logo />
      </Link>
      <Nav>
        <p>About</p>
        <p>Services</p>
        <p>Contact</p>
      </Nav>
    </NavWrap>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
